import {
	IStructureStore,
	IStructureAPI,
	PageAssetsLoaderSymbol,
	IPageAssetsLoader,
	Structure,
	IBaseStructureAPI,
} from '@wix/thunderbolt-symbols'
import { withDependencies } from '@wix/thunderbolt-ioc'
import { ComponentsLoaderSymbol, IComponentsLoader } from '@wix/thunderbolt-components-loader'
import { BaseStructureAPISym } from './symbols'

const getPageWrapperComponentId = (pageId: string, contextId: string = pageId) =>
	pageId === contextId ? `${pageId}_wrapper` : contextId

const structureAPI = (
	structureStore: IStructureStore,
	baseStructureAPI: IBaseStructureAPI,
	componentsLoader: IComponentsLoader,
	pageAssetsLoader: IPageAssetsLoader
): IStructureAPI => {
	return {
		...baseStructureAPI,
		addShellStructure: async () => {
			const structure = {
				DYNAMIC_STRUCTURE_CONTAINER: {
					components: [],
					componentType: 'DynamicStructureContainer',
				},
				'site-root': {
					components: [],
					componentType: 'DivWithChildren',
				},
				main_MF: {
					components: ['site-root', 'DYNAMIC_STRUCTURE_CONTAINER'],
					componentType: 'DivWithChildren',
				},
			}
			structureStore.update(structure)

			await Promise.all([
				componentsLoader.loadComponent('PageMountUnmount'),
				componentsLoader.loadComponents(structure),
			])
		},
		getPageWrapperComponentId,
		addPageAndRootToRenderedTree: (pageId: string, contextId: string) => {
			const pageBgId = `pageBackground_${pageId}`
			const hasPageBackground = structureStore.get(pageBgId)
			const rootComponents = ['SCROLL_TO_TOP', 'site-root', 'DYNAMIC_STRUCTURE_CONTAINER', 'SCROLL_TO_BOTTOM']

			if (hasPageBackground) {
				rootComponents.splice(1, 0, 'BACKGROUND_GROUP')
			}

			if (structureStore.get('WIX_ADS')) {
				rootComponents.splice(1, 0, 'WIX_ADS')
			}
			const wrapperId = getPageWrapperComponentId(pageId, contextId)
			const componentsToAdd = {
				main_MF: {
					components: rootComponents,
					componentType: 'DivWithChildren',
				},
				'site-root': {
					components: ['masterPage'],
					componentType: 'DivWithChildren',
				},
				SITE_PAGES: {
					componentType: 'PageGroup',
					components: [wrapperId],
				},
				[wrapperId]: { componentType: 'PageMountUnmount', components: [pageId] },
				SCROLL_TO_TOP: {
					components: [],
					componentType: 'Anchor',
				},
				SCROLL_TO_BOTTOM: {
					components: [],
					componentType: 'Anchor',
				},
				...(hasPageBackground && {
					BACKGROUND_GROUP: {
						componentType: 'BackgroundGroup',
						components: [pageBgId],
					},
				}),
			}

			structureStore.update(componentsToAdd)
		},
		addComponentToDynamicStructure: async (compId, compStructure, additionalComponents) => {
			const structure = {
				[compId]: compStructure,
				...additionalComponents,
			}
			structureStore.update(structure)
			await componentsLoader.loadComponents(structure)

			const { components } = structureStore.get('DYNAMIC_STRUCTURE_CONTAINER')
			structureStore.update({
				DYNAMIC_STRUCTURE_CONTAINER: {
					componentType: 'DynamicStructureContainer',
					components: [...components, compId],
				},
			})
		},
		isComponentInDynamicStructure: (compId) => {
			const { components } = structureStore.get('DYNAMIC_STRUCTURE_CONTAINER')

			return components.includes(compId)
		},
		removeComponentFromDynamicStructure: (compId) => {
			const { components } = structureStore.get('DYNAMIC_STRUCTURE_CONTAINER')
			structureStore.update({
				DYNAMIC_STRUCTURE_CONTAINER: {
					componentType: 'DynamicStructureContainer',
					components: components.filter((id) => id !== compId),
				},
			})
			// should we delete the comp structure..?
		},
		removeComponentFromParent: (parentId, compId) => {
			const parent = structureStore.get(parentId)
			const components = parent.components.filter((id) => id !== compId)

			structureStore.update({
				[parentId]: { ...parent, components },
			})
		},
		addComponentToParent: (parentId, compId, index) => {
			const parent = structureStore.get(parentId)
			const components = index
				? [...parent.components.slice(0, index), compId, ...parent.components.slice(index)]
				: [...parent.components, compId]

			structureStore.update({
				[parentId]: { ...parent, components },
			})
		},
		replaceComponentInParent: (parentId, oldCompId, newCompId) => {
			const parent = structureStore.get(parentId)
			const components = [...parent.components]

			const compIndex = components.indexOf(oldCompId)
			if (compIndex > -1) {
				components[compIndex] = newCompId

				structureStore.update({
					[parentId]: { ...parent, components },
				})
			}
		},
		loadPageStructure: async (pageId: string, contextId: string) => {
			const pageStructure = await pageAssetsLoader.load(pageId).components
			structureStore.setChildStore(contextId, pageStructure)
			return pageStructure
		},
		cleanPageStructure: (contextId: string) => {
			structureStore.setChildStore(contextId)
		},
	}
}

export const StructureAPI = withDependencies(
	[Structure, BaseStructureAPISym, ComponentsLoaderSymbol, PageAssetsLoaderSymbol],
	structureAPI
)
