import {
	IPropsStore,
	Props,
	BusinessLogger,
	BusinessLoggerSymbol,
	ReporterSymbol,
	IReporterApi,
	IPlatformPropsSyncManager,
	PlatformPropsSyncManagerSymbol,
} from '@wix/thunderbolt-symbols'
import { withDependencies, optional } from '@wix/thunderbolt-ioc'
import { CreateCompControllerArgs } from './types'

export const controlledComponentFactory = withDependencies(
	[Props, PlatformPropsSyncManagerSymbol, optional(BusinessLoggerSymbol), optional(ReporterSymbol)],
	(
		propsStore: IPropsStore,
		platformPropsSyncManager: IPlatformPropsSyncManager,
		businessLogger: BusinessLogger,
		reporter?: IReporterApi
	) => {
		const createCompControllerArgs: CreateCompControllerArgs = (displayedId: string) => {
			return {
				...(reporter && { trackEvent: reporter.trackEvent }),
				// @ts-ignore
				reportBi: (params, ctx) => {
					return businessLogger.logger.log(params, ctx)
				},
				updateProps: (overrideProps) => {
					propsStore.update({ [displayedId]: overrideProps })
					platformPropsSyncManager.triggerPlatformPropsSync(displayedId, overrideProps)
				},
			}
		}

		return {
			extendRendererProps() {
				return {
					createCompControllerArgs,
				}
			},
		}
	}
)
