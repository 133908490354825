import { IStructureStore, IDsStructureAPI, Structure, IBaseStructureAPI } from '@wix/thunderbolt-symbols'
import { withDependencies } from '@wix/thunderbolt-ioc'
import { BaseStructureAPISym } from './symbols'

const dsStructureAPI = (structureStore: IStructureStore, baseStructureAPI: IBaseStructureAPI): IDsStructureAPI => {
	//  add page to sitePages when structure is ready
	structureStore.subscribeToChanges(() => {
		const appStructure = structureStore.getEntireStore()
		const [pageId] = Object.entries(appStructure).filter(([, comp]) => comp.componentType === 'Page')[0] || []
		if (pageId && appStructure.SITE_PAGES && !appStructure.SITE_PAGES.components.includes(pageId)) {
			structureStore.update({
				SITE_PAGES: {
					...appStructure.SITE_PAGES,
					components: [pageId],
				},
			})
		}
	})
	return {
		...baseStructureAPI,
		update: (partial) => {
			structureStore.update(partial)
		},
	}
}

export const DsStructureAPI = withDependencies([Structure, BaseStructureAPISym], dsStructureAPI)
