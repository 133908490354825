import React, { Fragment, ComponentType, ReactNode, useEffect } from 'react'

export type PageMountUnmountProps = {
	children: () => ReactNode
	pageDidMount: (isMounted: boolean) => void
}

const PageMountUnmount: ComponentType<PageMountUnmountProps> = ({ children, pageDidMount }) => {
	useEffect(() => {
		pageDidMount(true)
		return () => {
			pageDidMount(false)
		}
	}, [pageDidMount])
	return <Fragment>{children()}</Fragment>
}

export default PageMountUnmount
