import { withDependencies } from '@wix/thunderbolt-ioc'
import { IPageDidMountHandler, pageIdSym } from '@wix/thunderbolt-symbols'
import { IUrlHistoryManager, UrlHistoryManagerSymbol } from 'feature-router'
import { IReporterApi } from '../types'
import { ReporterSymbol } from '../symbols'
import { reportPageView } from '../report-page-view'
import { IPageNumber, PageNumberSymbol } from 'feature-business-logger'
import { SeoSiteSymbol, ISeoSiteApi } from 'feature-seo'

const reporterNavigationHandlerFactory = (
	reporterApi: IReporterApi,
	urlHistoryManager: IUrlHistoryManager,
	pageNumberApi: IPageNumber,
	seoApi: ISeoSiteApi,
	pageId: string
): IPageDidMountHandler => ({
	pageDidMount: async () => {
		const parsedUrl = urlHistoryManager.getParsedUrl()
		const pageNumber = pageNumberApi.getPageNumber()
		const pageTitle = pageNumber > 1 ? await seoApi.getPageTitle() : window.document.title
		reportPageView({ reporterApi, parsedUrl, pageNumber, pageId, pageTitle })
	},
})

export const ReporterNavigationHandler = withDependencies(
	[ReporterSymbol, UrlHistoryManagerSymbol, PageNumberSymbol, SeoSiteSymbol, pageIdSym],
	reporterNavigationHandlerFactory
)
