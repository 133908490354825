import { withDependencies } from '@wix/thunderbolt-ioc'
import {
	DomReadySymbol,
	HeadContentSymbol,
	IHeadContent,
	ILogger,
	LoggerSymbol,
	ViewerModel,
	ViewerModelSym,
} from '@wix/thunderbolt-symbols'
import { PageResourceFetcherSymbol } from './symbols'
import { IPageResourceFetcher } from './IPageResourceFetcher'

export type ILoadPageStyle = {
	load(pageId: string): Promise<void>
}

const fetchCssAndMeasureIfNeeded = async (
	fetchResource: () => Promise<string>,
	mode: 'on' | 'off' | 'ignore',
	{ logger, pageId }: { logger: ILogger; pageId: string }
) => {
	if (mode === 'ignore') {
		return fetchResource()
	}
	const interactionKey = `ooi_css_optimization__${mode}`
	logger.interactionStarted(interactionKey, { customParams: { pageId } })
	const result = await fetchResource()
	logger.interactionEnded(interactionKey, { customParams: { pageId } })
	return result
}

const resolveOoiOptimizationMode = (viewerModel: ViewerModel) => {
	const ooiCssModules = viewerModel.siteAssets.modulesParams.css.ooiCssModules
	if (ooiCssModules === null) {
		return 'ignore'
	}
	return viewerModel.experiments['specs.thunderbolt.ooi_css_optimization_poc'] ? 'on' : 'off'
}

export const ClientPageStyleLoader = withDependencies<ILoadPageStyle>(
	[PageResourceFetcherSymbol, DomReadySymbol, LoggerSymbol, ViewerModelSym],
	(
		pageResourceFetcher: IPageResourceFetcher,
		domReadyPromise: Promise<void>,
		logger: ILogger,
		viewerModel: ViewerModel
	) => ({
		async load(pageId): Promise<void> {
			const id = `css_${pageId}`
			await domReadyPromise

			const mode = resolveOoiOptimizationMode(viewerModel)

			if (document.getElementById(id)) {
				if (mode !== 'ignore') {
					// we absue interaction started because we only need to send a single event (for marking this session)
					logger.interactionStarted(`ooi_css_optimization_idle__${mode}`, { customParams: { pageId } })
				}
				return
			}

			const css = await fetchCssAndMeasureIfNeeded(
				() => pageResourceFetcher.fetchResource(pageId, 'css', 'enable'),
				mode,
				{
					pageId,
					logger,
				}
			)

			const styleElement = window.document.createElement('style')
			styleElement.setAttribute('id', id)
			styleElement.innerHTML = css
			window.document.getElementById('pages-css')!.appendChild(styleElement)
		},
	})
)

export const ServerPageStyleLoader = withDependencies<ILoadPageStyle>(
	[HeadContentSymbol, PageResourceFetcherSymbol, LoggerSymbol, ViewerModelSym],
	(
		headContent: IHeadContent,
		pageResourceFetcher: IPageResourceFetcher,
		logger: ILogger,
		viewerModel: ViewerModel
	) => ({
		async load(pageId) {
			const css = await fetchCssAndMeasureIfNeeded(
				() => pageResourceFetcher.fetchResource(pageId, 'css', 'enable'),
				resolveOoiOptimizationMode(viewerModel),
				{
					pageId,
					logger,
				}
			)
			headContent.addPageCss(`<style id="css_${pageId}">${css}</style>`)
		},
	})
)
