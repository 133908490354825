import { IReporterOptions } from '@wix/thunderbolt-symbols'
import { IReporterApi } from '.'

export function reportPageView({
	reporterApi,
	parsedUrl,
	pageNumber,
	pageId = '',
	reporterOptions = {},
	pageTitle = window.document.title,
}: {
	reporterApi: IReporterApi
	parsedUrl: URL
	pageNumber: number
	pageId?: string
	reporterOptions?: IReporterOptions
	pageTitle?: string
}): void | undefined {
	if (pageId === 'masterPage') {
		return
	}

	const pageData = {
		pagePath: parsedUrl.pathname.concat(parsedUrl.search),
		pageTitle,
		pageId,
		pageNumber,
	}

	reporterApi.trackEvent(
		{
			eventName: 'PageView',
			params: pageData,
			options: { reportToManagedChannels: true, context: { isFirstVisit: pageNumber === 1 } },
		},
		reporterOptions
	)
}
