import { IBaseStructureAPI, IStructureStore, Structure } from '@wix/thunderbolt-symbols'
import { withDependencies } from '@wix/thunderbolt-ioc'

const baseStructureAPI = (structureStore: IStructureStore): IBaseStructureAPI => {
	return {
		get: (compId) => structureStore.get(compId),
		subscribeToChanges: (partial) => structureStore.subscribeToChanges(partial),
		getEntireStore: () => structureStore.getEntireStore(),
		getContextIdOfCompId: (compId: string) => structureStore.getContextIdOfCompId(compId),
		replaceComponentInParent: (parentId, oldCompId, newCompId) => {
			const parent = structureStore.get(parentId)
			const components = [...parent.components]

			const compIndex = components.indexOf(oldCompId)
			if (compIndex > -1) {
				components[compIndex] = newCompId

				structureStore.update({
					[parentId]: { ...parent, components },
				})
			}
		},
	}
}

export const BaseStructureAPI = withDependencies([Structure], baseStructureAPI)
