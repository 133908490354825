import { ContainerModuleLoader } from '@wix/thunderbolt-ioc'
import { LifeCycle } from '@wix/thunderbolt-symbols'
import { PageWillMountPropsExtender } from './pageWillMountPropsExtender'

export const page: ContainerModuleLoader = (bind) => {
	bind(LifeCycle.PageWillMountHandler).to(PageWillMountPropsExtender)
}

export { ComponentPropsExtenderSymbol } from './symbols'
export { IComponentPropsExtender } from './types'
