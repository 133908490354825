import { withDependencies } from '@wix/thunderbolt-ioc'
import {
	ComponentsStylesOverridesSymbol,
	IComponentsStylesOverrides,
	SdkHandlersProvider,
} from '@wix/thunderbolt-symbols'
import { AnimationData, IAnimations, WixCodeAnimationsHandlers } from '../types'
import { Animations } from '../symbols'

const CLEAR_PROPS = 'clip,clipPath,webkitClipPath,opacity,transform,visibility'
export const wixCodeHandlersProvider = withDependencies(
	[Animations, ComponentsStylesOverridesSymbol],
	(
		animations: IAnimations,
		componentsStylesOverrides: IComponentsStylesOverrides
	): SdkHandlersProvider<WixCodeAnimationsHandlers> => {
		return {
			getSdkHandlers() {
				return {
					async runAnimation(animationData: AnimationData, animationDirection: 'in' | 'out'): Promise<void> {
						let resolvePromise: () => void
						const animationCompletePromise = new Promise<void>((resolve) => {
							resolvePromise = resolve
						})
						const animatorManager = await animations.getInstance()

						const baseClearData = {
							name: 'BaseClear',
							targetId: animationData.targetId,
							duration: 0,
							delay: 0,
							params: {
								props: CLEAR_PROPS,
								immediateRender: false,
							},
						}

						animationData.params = {
							...animationData.params,
							callbacks: {
								onStart() {
									if (animationDirection === 'in') {
										componentsStylesOverrides.update({
											// targetId is the compId associated with the componentSDK instance
											[animationData.targetId as string]: {
												visibility: null,
											},
										})
									}
								},
								onComplete() {
									if (animationDirection === 'out') {
										// update visibility state using style overrides before baseClearData animation removes inline visibility style to avoid flickering
										componentsStylesOverrides.update({
											[animationData.targetId as string]: {
												visibility: 'hidden !important',
											},
										})
									}
								},
							},
						}

						animatorManager.runSequence(
							[
								{ type: 'Animation', data: animationData },
								{ type: 'Animation', data: baseClearData },
							],
							{
								callbacks: {
									onComplete: () => resolvePromise(),
									// TODO maybe onInterrupt and onReverseComplete not needed for platform handler?
									onInterrupt: () => resolvePromise(),
								},
							}
						)
						return animationCompletePromise
					},
				}
			},
		}
	}
)
