import React, { ComponentType } from 'react'

export type AppControllerCompProps = {
	id: string
}

const AppController: ComponentType<AppControllerCompProps> = ({ id }) => {
	// TODO we probably do not need this dom element. figure out why it's here.
	return <div id={id}></div>
}

export default AppController
